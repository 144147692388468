// extracted by mini-css-extract-plugin
export var active = "examples-module--active--56294";
export var badge = "examples-module--badge--4cfe1";
export var card = "examples-module--card--173fe";
export var content = "examples-module--content--6636a";
export var examples = "examples-module--examples--6972c";
export var filterBtn = "examples-module--filter-btn--517e3";
export var filters = "examples-module--filters--998ff";
export var gradient = "examples-module--gradient--52f7b";
export var logo = "examples-module--logo--05c5c";
export var nav = "examples-module--nav--69de7";
export var offsetCard = "examples-module--offset-card--66224";
export var previewModal = "examples-module--preview-modal--e0f4c";
export var separator = "examples-module--separator--6c7d4";
export var thumbnail = "examples-module--thumbnail--76b1a";