import * as styles from './examples.module.scss';

import { GatsbyImage, getImage, getSrc } from 'gatsby-plugin-image';
import { Link, graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';

import Brand from '../components/brand';
import Button from '../components/button';
import Footer from '../components/v2/footer';
import Header from '../components/v2/header';
import InteractEmbed from '../components/utils/interact-embed';
import Modal from '../components/modal';
import SEO from '../components/seo';
import Section from '../components/section';
import examples from '../content/examples';

const categories = [
  { id: 'coaching', label: 'Coaching' },
  { id: 'wellness', label: 'Wellness' },
  { id: 'copywriting', label: 'Copywriting' },
  { id: 'nonprofit', label: 'Non-Profit' },
  { id: 'marketing', label: 'Marketing' },
  { id: 'ecommerce', label: 'E-Commerce' }
];

const ExamplesPage = ({ data }) => {
  const exampleImages = data.exampleImages.edges;
  const [results, setResults] = useState(examples.filter(e => e.active !== false));
  const [active, setActive] = useState();
  const [category, setCategory] = useState();

  const handleCategory = (value) => {
    setCategory(value);
  };

  useEffect(() => {
    let arr = examples.filter(e => {
      return !category || e.category === category;
    });
    setResults(arr);
  }, [category]);

  return (
    <>
      <SEO
        title="Live Examples"
        description="Take live quizzes and experience how interactive quizzes function. Each of these quizzes is from an Interact customer - they are using their quizzes for lead generation, product recommendations, and social media promotions."
        />
      <Header btnTheme="primary" />
      <main className={ styles.examples }>
        <Section theme="white" style={ { marginTop: `4.5rem` } }>
          <div className="row justify-content-center">
            <div className="col-12 col-lg-8 text-center">
              <h1 className="heading-xl color-black">Quiz Examples</h1>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-lg-8 text-center">
              <h2 className="text-body color-muted-foreground">Take live quizzes and experience how interactive quizzes function. Each of these quizzes is from an Interact customer - they are using their quizzes for lead generation, product recommendations, and social media promotions.</h2>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-lg-8">
              <div className={ styles.filters }>
                <button className={ [styles.filterBtn, !category ? styles.active : ''].join(' ') } onClick={ () => handleCategory() }>
                  <span>All</span>
                  <span className={ styles.badge}>{ examples.length }</span>
                </button>
                {
                  categories.map(c => (
                    <button key={ c.id }  className={ [styles.filterBtn, category === c.id ? styles.active : ''].join(' ') } onClick={ () => handleCategory(c.id) }>
                      <span>{ c.label }</span>
                      <span className={ styles.badge }>{ examples.filter(i => i.category === c.id).length }</span>
                    </button>
                  ))
                }
              </div>
            </div>
          </div>
        </Section>
        <Section theme="grey">
          <div className="row">
            {
              results.map((e, i) => {
                const { slug, title, category } = e
                const img = exampleImages.find(({ node }) => getSrc(node).includes(slug))

                return (
                  <div className="col-md-6 col-lg-4" key={ e.slug } style={ { marginBottom: `1.5rem` } }>
                    <div className={ styles.card }
                      onClick={ () => setActive(e) }
                      onKeyPress={ () => setActive(e) }
                      role="button"
                      tabIndex={ i }>
                      <div className={ styles.thumbnail }>
                        <GatsbyImage image={ getImage(img.node) } />
                        <div className={ styles.gradient }></div>
                        <Brand id={ slug } className={ styles.logo }/>
                      </div>
                      <div className={ styles.content }>
                        <p className="text-body color-black">{ title }</p>
                        <p className={ styles.badge }>{ categories.find(c => c.id === category).label }</p>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </Section>
        <Section theme="purple500" style={ { padding: `5rem 0` } }>
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-8 text-center">
              <p className="heading-xl color-white">Get started today</p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-8 col-xl-6 text-center">
              <p className="heading-sm color-white text-center" style={{ marginTop: '0', marginBottom: '2rem' }}>Use our AI quiz maker to create a beautiful quiz for your brand in a minute.</p>
              <Button theme="white" size="large" href="https://ai.tryinteract.com/create">Make a quiz - for free</Button>
            </div>
          </div>
        </Section>
        { active ? (
          <Modal className={ styles.previewModal } isOpen={ true } onClose={ () => setActive() }>
            <div className={ styles.content }>
              <Brand id={ active.slug } className={ styles.logo }/>
              <InteractEmbed id={ active.id } disableCover={ active.options && active.options.disableCover } />
            </div>
          </Modal>
        ) : null }
      </main>
      <Footer/>
    </>
  )
}

export default ExamplesPage;

export const query = graphql`
  query {
    exampleImages: allFile(filter: {extension: {regex: "/(jpg)|(png)|(jpeg)/"}, relativeDirectory: {eq: "examples"}}) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData (
              width: 476,
              height: 262,
              quality: 100,
              transformOptions: {
                cropFocus: CENTER,
              }
            )
          }
        }
      }
    }
  }
`